import payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44 from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_Nj2_b3l6C4_7iqBi3XRbkt2hrEpLdJINOebEjjboJOc from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_bufferutil@4.0.9_db0@0.3.1_ioredi_4a1e0124a4619277fce655277b5fb686/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_IsAZqg9K34uAUzf_m1TvfrGWyox9gOR_pRjm9lVPLPk from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/vuetify-nuxt-module@0.18.5_magicast@0.3.5_typescript@5.8.2_vite@6.2.5_@types+node@22.14_0330b5bc0a8561eb600bc5e67c3c08e5/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_oZjjXfhq5Ogjr0WEYDrEtp9lVmX2NoLZv4kztCG1_5k from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_bufferutil@4.0.9_db0@0.3.1_ioredi_4a1e0124a4619277fce655277b5fb686/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Nwz_hwY0pRsHw4ETxz1tAndOS8Ag9KBxsDAZBv754_s from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_bufferutil@4.0.9_db0@0.3.1_ioredi_4a1e0124a4619277fce655277b5fb686/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_OSmUZJdHcfl40ZLfDA3ltZ2V7eB8RluQ8nUTURtSzIw from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_bufferutil@4.0.9_db0@0.3.1_ioredi_4a1e0124a4619277fce655277b5fb686/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_8mZTwUYFj_rDn4ecr8xTgOgA1yBHSBi1rep9TtELBrc from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_bufferutil@4.0.9_db0@0.3.1_ioredi_4a1e0124a4619277fce655277b5fb686/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ONgYGMJOiJJQIpUtGD8zqI4ttzkXndTexgAIpN6T_FI from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_bufferutil@4.0.9_db0@0.3.1_ioredi_4a1e0124a4619277fce655277b5fb686/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_wQFS8PFnMShIT_i8dVNFaNqYpWqX1_8HD81zzWfpMz0 from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_bufferutil@4.0.9_db0@0.3.1_ioredi_4a1e0124a4619277fce655277b5fb686/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/buildhome/repo/addicted.nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_47LiYIIx3L_oAc6tkdQvUyoEmsk8aq45GunnUJdWjaA from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_bufferutil@4.0.9_db0@0.3.1_ioredi_4a1e0124a4619277fce655277b5fb686/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_NPi_Ixo1sLCQtQhYgcq9HdVO7xR0RGTjgeae7FdmR4E from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescr_0207495bb835a6f5ae7860b54eb3353f/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import vuetify_no_client_hints_lGMiza9soXyrJsboWkp_f_rBcbaxBF1f4u1tUxLSLtE from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/vuetify-nuxt-module@0.18.5_magicast@0.3.5_typescript@5.8.2_vite@6.2.5_@types+node@22.14_0330b5bc0a8561eb600bc5e67c3c08e5/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import cloudflare_client_oW5bnf83cb7GTw3aOBzDnr4mehF54Zf4RZop2HrWkT8 from "/opt/buildhome/repo/addicted.nuxt/plugins/cloudflare.client.ts";
import matomo_client_UybCn6fhPv7R9wxUYT7nkAadiBSV3zdRIuMtbFk_H5Q from "/opt/buildhome/repo/addicted.nuxt/plugins/matomo.client.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/opt/buildhome/repo/addicted.nuxt/plugins/sentry.client.ts";
import vuetify_nuxt_plugin_client_DI1wm4qdqocRtY45JXrDvTzqqhOG2L83Lu4GY06gtpk from "/opt/buildhome/repo/addicted.nuxt/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44,
  revive_payload_client_Nj2_b3l6C4_7iqBi3XRbkt2hrEpLdJINOebEjjboJOc,
  vuetify_icons_IsAZqg9K34uAUzf_m1TvfrGWyox9gOR_pRjm9lVPLPk,
  unhead_oZjjXfhq5Ogjr0WEYDrEtp9lVmX2NoLZv4kztCG1_5k,
  router_Nwz_hwY0pRsHw4ETxz1tAndOS8Ag9KBxsDAZBv754_s,
  payload_client_OSmUZJdHcfl40ZLfDA3ltZ2V7eB8RluQ8nUTURtSzIw,
  navigation_repaint_client_8mZTwUYFj_rDn4ecr8xTgOgA1yBHSBi1rep9TtELBrc,
  check_outdated_build_client_ONgYGMJOiJJQIpUtGD8zqI4ttzkXndTexgAIpN6T_FI,
  chunk_reload_client_wQFS8PFnMShIT_i8dVNFaNqYpWqX1_8HD81zzWfpMz0,
  plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_47LiYIIx3L_oAc6tkdQvUyoEmsk8aq45GunnUJdWjaA,
  plugin_NPi_Ixo1sLCQtQhYgcq9HdVO7xR0RGTjgeae7FdmR4E,
  vuetify_no_client_hints_lGMiza9soXyrJsboWkp_f_rBcbaxBF1f4u1tUxLSLtE,
  plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk,
  cloudflare_client_oW5bnf83cb7GTw3aOBzDnr4mehF54Zf4RZop2HrWkT8,
  matomo_client_UybCn6fhPv7R9wxUYT7nkAadiBSV3zdRIuMtbFk_H5Q,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  vuetify_nuxt_plugin_client_DI1wm4qdqocRtY45JXrDvTzqqhOG2L83Lu4GY06gtpk
]